import '../../website_theme/plugins/slick/slick.min';
$(document).ready(function ($) {
    $('.hero').slick({
        lazyLoad: 'ondemand',
        dots: false,
        infinite: true,
        speed: 1000,
        fade: !0,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: true,
        arrows: false
    });
});
